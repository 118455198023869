<!--
 * @Author: ch3nh2
 * @Date: 2022-11-01 09:35:26
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-09-01 01:34:42
 * @FilePath: \beilunchanye_fe_20211208\src\views\group.vue
 * @Description: none
-->
<template>
	<div class="group">
		<div class="group-main" v-show="!$store.state.loadingOk">
			<!-- 头标题 -->
			<transition name="el-zoom-in-top">
				<div
					v-show="headerTran"
					class="group-header"
					:style="{
						backgroundImage: `url(${require('../assets/images/group/header_' + $store.state.loginType + '.png')})`,
					}"
				>
					<div class="group-header-wrap">
						<p>{{ timeValue }} {{ weatherValue.weather }} {{ weatherValue.temperature }}℃</p>
						<div class="group-header-menu">
							<div class="group-header-menu-item" @click="goScreenfull">
								<img v-if="!screenfull" src="../assets/images/weixing_full_icon.png" alt="" />
								<img v-else src="../assets/images/weixing_out_full_icon.png" alt="" />
								<span>{{ screenfull ? '退出全屏' : '全屏' }}</span>
							</div>
							<div class="group-header-menu-item" @click="arrountOut">
								<img src="../assets/images/sign_out_icon6.png" alt="" />
								<span>退出</span>
							</div>
						</div>
					</div>
				</div>
			</transition>
			<!-- 中心地图 -->
			<transition name="el-zoom-in-center">
				<div v-show="mapTran" class="group-map">
					<div :class="`group-map-region-wrap group-map-region-wrap-0${item}`" v-for="(item, index) in 4" :key="index">
						<div :class="`group-map-region group-map-region-0${jtem}`" v-for="(jtem, jndex) in 4" :key="jndex"></div>
					</div>
					<div class="group-map-dot-wrap">
						<div :class="`group-map-dot group-map-dot-${index + 1}`" v-for="(item, index) in mapDotArr" :key="index">
							<p>{{ item }}</p>
							<i></i>
						</div>
					</div>
				</div>
			</transition>
			<!-- 主要数据 -->
			<div class="group-wrap">
				<!-- 中心数据 -->
				<transition name="el-zoom-in-center">
					<div v-show="centerTran" class="group-center">
						<div class="group-center-item group-center-item-01">
							<div class="group-center-item-icon"></div>
							<div class="group-center-item-data">
								<div class="group-center-item-name">证载房产总面积</div>
								<div class="group-center-item-num">
									<template v-if="overview && overview.asset_area"> <CountUp :delay="1000" :endVal="Number(overview.asset_area)" :options="{ decimalPlaces: 1 }" />万m² </template>
									<span v-else>-</span>
								</div>
							</div>
						</div>
						<!-- <div class="group-center-item group-center-item-02">
              <div class="group-center-item-icon"></div>
              <div class="group-center-item-data">
                <div class="group-center-item-name">资产总原值</div>
                <div class="group-center-item-num">
                  <template v-if="overview && overview.asset_total_value">
                    <CountUp :delay="1000" :endVal="Number(overview.asset_total_value)" />万元
                  </template>
                  <span v-else>-</span>
                </div>
              </div>
            </div> -->
						<div class="group-center-item group-center-item-03">
							<div class="group-center-item-icon"></div>
							<div class="group-center-item-data">
								<div class="group-center-item-name">总租赁面积</div>
								<div class="group-center-item-num">
									<template v-if="overview && overview.full_rent_area"> <CountUp :delay="1000" :endVal="Number(overview.full_rent_area)" :options="{ decimalPlaces: 1 }" />万m² </template>
									<span v-else>-</span>
								</div>
							</div>
						</div>
						<div class="group-center-item group-center-item-04">
							<div class="group-center-item-icon"></div>
							<div class="group-center-item-data">
								<div class="group-center-item-name">当前年租金收入</div>
								<div class="group-center-item-num">
									<template v-if="overview && overview.rental_income"> <CountUp :delay="1000" :endVal="Number(overview.rental_income)" :options="{ decimalPlaces: 1, useGrouping: false }" />万元 </template>
									<span v-else>-</span>
								</div>
							</div>
						</div>
					</div>
				</transition>
				<!-- 资产概况 -->
				<transition name="el-zoom-in-center">
					<div v-show="itemTran1" class="group-item group-item-left group-item-01">
						<div class="group-item-content">
							<div class="group-item-content-left">
								<div id="RentalChart" :style="{ width: '200px', height: '200px' }"></div>
								<div class="rentalChart-mask">
									<div class="rentalChart-line" v-for="(item, index) in 14" :key="index" :style="{ transform: `rotate(${index * 30}deg)` }"></div>
								</div>
								<div class="rentalChart-label-wrap">
									<div class="rentalChart-label-value">
										<CountUp v-if="overview && overview.full_rent_area && overview.rent_area" :delay="1000" :endVal="Number((overview.rent_area / overview.full_rent_area) * 100)" :options="{ decimalPlaces: 2 }" />
										<span v-else>-</span>
										<span>(%)</span>
									</div>
									<div class="rentalChart-label-name">出租率</div>
								</div>
							</div>
							<div class="group-item-content-right">
								<div class="group-item-content-right-item">
									<div class="group-item-content-right-item-icon">
										<div class="group-item-content-right-item-icon-rect"></div>
										<div class="group-item-content-right-item-icon-line"></div>
									</div>
									<div class="group-item-content-right-item-data">
										<div class="group-item-content-right-item-value">
											<CountUp v-if="overview && overview.rent_area" :delay="1000" :endVal="Number(overview.rent_area)" :options="{ decimalPlaces: 1 }" />
											<span v-else>-</span>
										</div>
										<div class="group-item-content-right-item-label">已租赁面积(万㎡)</div>
									</div>
								</div>
								<div class="group-item-content-right-item">
									<div class="group-item-content-right-item-icon">
										<div class="group-item-content-right-item-icon-rect"></div>
										<div class="group-item-content-right-item-icon-line"></div>
									</div>
									<div class="group-item-content-right-item-data">
										<div class="group-item-content-right-item-value">
											<CountUp v-if="overview && overview.not_rent_area" :delay="1000" :endVal="Number(overview.not_rent_area)" :options="{ decimalPlaces: 1 }" />
											<span v-else>-</span>
										</div>
										<div class="group-item-content-right-item-label">未租赁面积(万㎡)</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</transition>
				<!-- 资产项目 -->
				<transition name="el-zoom-in-center">
					<div v-show="itemTran2" class="group-item group-item-left group-item-02">
						<div class="group-item-content">
							<div class="group-item-content-data">
								<div class="group-item-content-data-item">
									<div class="group-item-content-data-value">
										<CountUp v-if="overview && overview.all_project_nm" :delay="1000" :endVal="Number(overview.all_project_nm)" />
										<span v-else>-</span>
									</div>
									<div class="group-item-content-data-label">总项目数</div>
								</div>
								<div class="group-item-content-data-item">
									<div class="group-item-content-data-value">
										<CountUp v-if="overview && overview.operate_project_nm" :delay="1000" :endVal="Number(overview.operate_project_nm)" />
										<span v-else>-</span>
									</div>
									<div class="group-item-content-data-label">运营项目</div>
								</div>
								<div class="group-item-content-data-item">
									<div class="group-item-content-data-value">
										<CountUp v-if="overview && overview.build_project_nm" :delay="1000" :endVal="Number(overview.build_project_nm)" />
										<span v-else>-</span>
									</div>
									<div class="group-item-content-data-label">在建项目</div>
								</div>
							</div>
							<div class="group-item-content-chart">
								<div class="group-item-content-chart-item">
									<div id="OperateChart" :style="{ width: '150px', height: '150px' }"></div>
									<div class="group-item-content-chart-data">
										<div class="group-item-content-chart-value">
											<CountUp v-if="overview && overview.operate_project_nm && overview.all_project_nm" :delay="1000" :endVal="Number((overview.operate_project_nm / overview.all_project_nm) * 100)" :options="{ decimalPlaces: 1 }" />
											<span v-else>-</span>
											(%)
										</div>
										<div class="group-item-content-chart-label">运营项目</div>
									</div>
								</div>
								<div class="group-item-content-chart-item">
									<div id="ConstructionChart" :style="{ width: '150px', height: '150px' }"></div>
									<div class="group-item-content-chart-data">
										<div class="group-item-content-chart-value">
											<CountUp v-if="overview && overview.build_project_nm && overview.all_project_nm" :delay="1000" :endVal="Number((overview.build_project_nm / overview.all_project_nm) * 100)" :options="{ decimalPlaces: 1 }" />
											<span v-else>-</span>
											(%)
										</div>
										<div class="group-item-content-chart-label">在建项目</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</transition>
				<!-- 产权分布 -->
				<transition name="el-zoom-in-center">
					<div v-show="itemTran3" class="group-item group-item-left group-item-03">
						<div class="group-item-content">
							<div class="group-item-content-data">
								<div class="group-item-content-data-item" v-for="(item, index) in propertys" :key="index">
									<div class="group-item-content-data-item-name">
										<div class="group-item-content-data-item-name-text">
											{{ item.name }}
										</div>
										<div class="group-item-content-data-item-name-value"><CountUp :delay="1000" :endVal="Number(item.num)" />个</div>
									</div>
									<div class="group-item-content-data-item-value">
										<div class="group-item-content-data-item-value-mix" :style="{ width: `${item.proportion}%` }"></div>
									</div>
									<div class="group-item-content-data-item-num">
										<span>{{ (item.square / 10000).toFixed(2) || '-' }}万m²</span>
										<span>{{ item.proportion }} %</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</transition>
				<!-- 集群面积 -->
				<transition name="el-zoom-in-center">
					<div v-show="itemTran4" class="group-item group-item-left group-item-04">
						<div class="group-item-content">
							<div class="group-item-content-wrap">
								<el-collapse accordion>
									<el-collapse-item>
										<template slot="title">
											<span>医疗健康产业集群</span>
											<span>(<b>14.09</b>万㎡)</span>
										</template>
										<div class="group-item-content-data">
											<div class="group-item-content-data-item">
												<div class="group-item-content-data-icon"></div>
												<div class="group-item-content-data-data">
													<div class="group-item-content-data-value"><span>5.84</span>万m²</div>
													<div class="group-item-content-data-name">海星医药健康创新园A区</div>
												</div>
											</div>
											<div class="group-item-content-data-item">
												<div class="group-item-content-data-icon"></div>
												<div class="group-item-content-data-data">
													<div class="group-item-content-data-value"><span>5.08</span>万m²</div>
													<div class="group-item-content-data-name">海星医药健康创新园B区</div>
												</div>
											</div>
											<div class="group-item-content-data-item">
												<div class="group-item-content-data-icon"></div>
												<div class="group-item-content-data-data">
													<div class="group-item-content-data-value"><span>3.17</span>万m²</div>
													<div class="group-item-content-data-name">海星医药健康产业园C区</div>
												</div>
											</div>
										</div>
									</el-collapse-item>
									<el-collapse-item>
										<template slot="title">
											<span>智能制造示范区</span>
											<span>(<b>2.92</b>万㎡)</span>
										</template>
										<div class="group-item-content-data">
											<div class="group-item-content-data-item">
												<div class="group-item-content-data-icon"></div>
												<div class="group-item-content-data-data">
													<div class="group-item-content-data-value"><span>2.92</span>万m²</div>
													<div class="group-item-content-data-name">卫星厂</div>
												</div>
											</div>
										</div>
									</el-collapse-item>
									<el-collapse-item>
										<template slot="title">
											<span>金融科技示范区</span>
											<span>(<b>1.62</b>万㎡)</span>
										</template>
										<div class="group-item-content-data">
											<div class="group-item-content-data-item">
												<div class="group-item-content-data-icon"></div>
												<div class="group-item-content-data-data">
													<div class="group-item-content-data-value"><span>1.62</span>万m²</div>
													<div class="group-item-content-data-name">融汇国际大厦</div>
												</div>
											</div>
										</div>
									</el-collapse-item>
									<el-collapse-item>
										<template slot="title">
											<span>人工智能集成电路产业走廊</span>
											<span>(<b>11.85</b>万㎡)</span>
										</template>
										<div class="group-item-content-data">
											<div class="group-item-content-data-item">
												<div class="group-item-content-data-icon"></div>
												<div class="group-item-content-data-data">
													<div class="group-item-content-data-value"><span>1.78</span>万m²</div>
													<div class="group-item-content-data-name">未来科技大厦</div>
												</div>
											</div>
											<div class="group-item-content-data-item">
												<div class="group-item-content-data-icon"></div>
												<div class="group-item-content-data-data">
													<div class="group-item-content-data-value"><span>4.91</span>万m²</div>
													<div class="group-item-content-data-name">中关村国际创新大厦</div>
												</div>
											</div>
											<div class="group-item-content-data-item">
												<div class="group-item-content-data-icon"></div>
												<div class="group-item-content-data-data">
													<div class="group-item-content-data-value"><span>0.62</span>万m²</div>
													<div class="group-item-content-data-name">中科大厦B区</div>
												</div>
											</div>
											<div class="group-item-content-data-item">
												<div class="group-item-content-data-icon"></div>
												<div class="group-item-content-data-data">
													<div class="group-item-content-data-value"><span>0.83</span>万m²</div>
													<div class="group-item-content-data-name">红博馆</div>
												</div>
											</div>
											<div class="group-item-content-data-item">
												<div class="group-item-content-data-icon"></div>
												<div class="group-item-content-data-data">
													<div class="group-item-content-data-value"><span>2.01</span>万m²</div>
													<div class="group-item-content-data-name">馨雅大厦</div>
												</div>
											</div>
											<div class="group-item-content-data-item">
												<div class="group-item-content-data-icon"></div>
												<div class="group-item-content-data-data">
													<div class="group-item-content-data-value"><span>1.70</span>万m²</div>
													<div class="group-item-content-data-name">大行基业</div>
												</div>
											</div>
										</div>
									</el-collapse-item>
								</el-collapse>
							</div>
						</div>
					</div>
				</transition>
				<!-- 项目面积分布及出租率 -->
				<transition name="el-zoom-in-center">
					<div v-show="itemTran5" class="group-item group-item-right group-item-05">
						<div class="group-item-content">
							<div class="group-item-content-wrap">
								<div class="group-item-content-title">
									<span>园区名称</span>
									<span>面积(㎡)</span>
									<span>出租率</span>
								</div>
								<div class="group-item-content-swiper">
									<swiper class="swiper" :options="swiperOption">
										<swiper-slide v-for="(item, index) in areaLeases" :key="index">
											<div class="group-item-content-data-wrap">
												<div class="group-item-content-data-item" v-for="(jtem, jndex) in item.list" :key="jndex">
													<div class="group-item-content-data-item-num">
														{{ jndex + 1 }}
													</div>
													<div class="group-item-content-data-item-info">
														<div class="group-item-content-data-item-data">
															<div class="group-item-content-data-item-name">
																{{ jtem.park_name }}
															</div>
															<div class="group-item-content-data-item-area">
																<CountUp v-if="jtem.area" :delay="1000" :endVal="Number(jtem.area)" />
																<span v-else>-</span>
															</div>
															<div class="group-item-content-data-item-lease">
																<CountUp v-if="jtem.rent_rate" :delay="1000" :endVal="Number(jtem.rent_rate)" :options="{ decimalPlaces: 2 }" />
																<span v-else>-</span>
																%
															</div>
														</div>
														<div class="group-item-content-data-item-line">
															<div class="group-item-content-data-item-background">
																<div class="group-item-content-data-item-progress" :style="{ width: `${jtem.lease}%` }">
																	<div class="group-item-content-data-item-progress-circle">
																		<div class="group-item-content-data-item-progress-dot"></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</swiper-slide>
										<div class="swiper-pagination" slot="pagination"></div>
									</swiper>
								</div>
							</div>
						</div>
					</div>
				</transition>
				<!-- 企业入驻 -->
				<transition name="el-zoom-in-center">
					<div v-show="itemTran6" class="group-item group-item-right group-item-06">
						<div class="group-item-content">
							<div class="group-item-content-data-wrap">
								<div class="group-item-content-data">
									<div class="group-item-content-data-item">
										<div class="group-item-content-data-icon">
											<span>家</span>
										</div>
										<div class="group-item-content-data-label">
											<div class="group-item-content-data-value">
												<CountUp v-if="overview && overview.company_nm" :delay="1000" :endVal="Number(overview.company_nm)" />
												<span v-else>-</span>
											</div>
											<div class="group-item-content-data-name">企业数</div>
										</div>
									</div>
									<div class="group-item-content-data-item">
										<div class="group-item-content-data-icon">
											<span>人</span>
										</div>
										<div class="group-item-content-data-label">
											<div class="group-item-content-data-value">
												<CountUp v-if="overview && overview.employee_nm" :delay="1000" :endVal="Number(overview.employee_nm)" :options="{ useGrouping: false }" />
												<span v-else>-</span>
											</div>
											<div class="group-item-content-data-name">员工数</div>
										</div>
									</div>
								</div>
								<div class="group-item-content-data-chart">
									<div id="SettleOutsideChart" class="chart-item" :style="{ width: '260px', height: '260px' }"></div>
									<div id="SettleCentreChart" class="chart-item" :style="{ width: '230px', height: '230px' }"></div>
									<div id="SettleWithinChart" class="chart-item" :style="{ width: '220px', height: '220px' }"></div>
									<div class="group-item-content-data-chart-mask">
										<div class="group-item-content-data-chart-circle"></div>
										<div class="group-item-content-data-chart-dot">
											<span>企业行业分布</span>
										</div>
									</div>
								</div>
								<div class="group-item-content-data-chart-legend">
									<div class="group-item-content-data-chart-legend-item" v-for="(item, index) in settleLegends" :key="index">
										<div class="group-item-content-data-chart-legend-icon" :style="{ background: settleColors[index] }"></div>
										<div class="group-item-content-data-chart-legend-label">
											{{ item }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
	import { logout } from '@/api/login';
	import { getGroupData } from '@/api';
	import { returnAllPageFunc } from '@/utils/utils';
	import { clearTimeout } from 'timers';
	import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
	import moment from 'moment';
	import CountUp from 'vue-countup-v2';
	import screenfull from 'screenfull';
	import 'swiper/css/swiper.css';
	export default {
		name: 'group',
		data() {
			return {
				timeValue: '',
				setTimeValue: '',
				weatherValue: {},
				screenfull: false,
				mapTran: false,
				headerTran: false,
				centerTran: false,
				itemTran1: false,
				itemTran2: false,
				itemTran3: false,
				itemTran4: false,
				itemTran5: false,
				itemTran6: false,
				transitionArr: [{ name: 'headerTran' }, { name: 'centerTran' }, { name: 'mapTran' }, { name: 'itemTran1' }, { name: 'itemTran2' }, { name: 'itemTran3' }, { name: 'itemTran4' }, { name: 'itemTran5' }, { name: 'itemTran6' }],
				mapDotArr: ['上庄医谷', '钢管厂', '未来', '创新', '丹棱', '彩和坊', '中科B区', '知春大厦', '大行基业', '红博馆', '鑫泰大厦', '馨雅大厦'],
				data: null,
				overview: null,
				propertys: [],
				settleColors: ['#29ECB2', '#31CAFC', '#FDED7F', '#1D7BFC'],
				settleLegends: [],
				swiperOption: {
					autoplay: true,
					pagination: {
						el: '.swiper-pagination',
						clickable: true,
					},
				},
				areaLeases: [],
			};
		},
		watch: {},
		computed: {},
		components: {
			CountUp,
			Swiper,
			SwiperSlide,
		},
		methods: {
			initTime() {
				const $this = this;
				this.timeValue = moment().format('YYYY年MM月DD日 dddd HH:mm:ss');
				this.setTimeValue = setInterval(() => {
					this.timeValue = moment().format('YYYY年MM月DD日 dddd HH:mm:ss');
				}, 1 * 1000);
				window.AMap.plugin('AMap.Weather', () => {
					let weather = new window.AMap.Weather();
					const requireWeather = () => {
						weather.getLive('北京', function (err, data) {
							$this.weatherValue = data;
						});
					};
					requireWeather();
				});
			},
			initTransitionShow() {
				for (let i = 0; i < this.transitionArr.length; i++) {
					setTimeout(() => {
						this[this.transitionArr[i].name] = true;
					}, 200 * i);
				}
			},
			initTransitionHide() {
				for (let i = 0; i < this.transitionArr.length; i++) {
					setTimeout(() => {
						this[this.transitionArr[i].name] = false;
					}, 200 * i);
				}
			},
			goScreenfull() {
				this.screenfull = !this.screenfull;
				if (this.screenfull) {
					screenfull.toggle();
				} else {
					screenfull.exit();
				}
			},
			arrountOut() {
				const codeValue = JSON.parse(localStorage.getItem('code'));
				if (codeValue.token) {
					logout({
						token: codeValue.token,
					}).then(() => {
						localStorage.removeItem('code');
						this.$store.commit('getPower');
						this.$store.commit('changeShowMapValue', false);
						this.$store.commit('changeLoadingStatus', true);
						this.$router.push('login');
						this.$store.mapType = false;
						this.$message.success('退出成功', 1);
					});
				} else {
					localStorage.removeItem('code');
					this.$store.commit('getPower');
					this.$store.commit('changeShowMapValue', false);
					this.$store.commit('changeLoadingStatus', true);
					this.$router.push('login');
					this.$store.mapType = false;
					this.$message.success('退出成功', 1);
				}
				this.$store.state.mapType = false;
			},
			drawRentalChart() {
				const chart = this.$echarts.init(document.getElementById('RentalChart'));
				chart.setOption({
					color: ['#46A4FB', '#00E8FF'],
					series: [
						{
							type: 'pie',
							radius: ['45%', '60%'],
							label: {
								show: false,
							},
							data: [(this.overview.rent_area / this.overview.full_rent_area) * 100, (this.overview.not_rent_area / this.overview.full_rent_area) * 100],
						},
					],
				});
			},
			drawOperateChart() {
				const chart = this.$echarts.init(document.getElementById('OperateChart'));
				chart.setOption({
					color: ['#35BDFF', '#005FA5'],
					series: [
						{
							type: 'pie',
							radius: ['54%', '60%'],
							label: {
								show: false,
							},
							data: [(this.overview.operate_project_nm / this.overview.all_project_nm) * 100, (this.overview.build_project_nm / this.overview.all_project_nm) * 100],
						},
					],
				});
			},
			drawConstructionChart() {
				const chart = this.$echarts.init(document.getElementById('ConstructionChart'));
				chart.setOption({
					color: ['#06EFF6', '#047482'],
					series: [
						{
							type: 'pie',
							radius: ['54%', '60%'],
							label: {
								show: false,
							},
							data: [(this.overview.build_project_nm / this.overview.all_project_nm) * 100, (this.overview.operate_project_nm / this.overview.all_project_nm) * 100],
						},
					],
				});
			},
			drawSettleOutsideChart() {
				const chart = this.$echarts.init(document.getElementById('SettleOutsideChart'));
				const data = [];
				this.data?.industry_distribute?.map(item => {
					data.push((item.company_nm / this.overview?.company_nm) * 100);
				});
				chart.setOption({
					color: ['#29ECB2', 'transparent', 'transparent', 'transparent'],
					series: [
						{
							type: 'pie',
							radius: ['94%', '100%'],
							label: {
								show: false,
							},
							data,
						},
					],
				});
			},
			drawSettleCentreChart() {
				const chart = this.$echarts.init(document.getElementById('SettleCentreChart'));
				const data = [];
				this.data?.industry_distribute?.map(item => {
					data.push((item.company_nm / this.overview?.company_nm) * 100);
				});
				chart.setOption({
					color: ['#0F4E4D', '#31CAFC', '#FDED7F', '#1D7BFC'],
					series: [
						{
							type: 'pie',
							radius: ['95%', '100%'],
							label: {
								show: false,
							},
							data,
						},
					],
				});
			},
			drawSettleWithinChart() {
				const chart = this.$echarts.init(document.getElementById('SettleWithinChart'));
				const data = [];
				this.data?.industry_distribute?.map(item => {
					data.push((item.company_nm / this.overview?.company_nm) * 100);
				});
				chart.setOption({
					color: ['#104E4D', '#124364', '#504E3E', '#0C3A64'],
					series: [
						{
							type: 'pie',
							radius: ['80%', '100%'],
							label: {
								show: false,
							},
							data,
						},
					],
				});
			},
			getGroupData() {
				getGroupData().then(res => {
					const { data } = res;
					this.data = data;
					this.overview = data?.overview;
					this.propertys = [
						{
							name: '承租资产',
							num: data?.overview?.leased_asset_nm || 0,
							square: data?.overview?.leased_asset_area || 0,
							proportion: ((data?.overview?.leased_asset_area / (data?.overview?.leased_asset_area + data?.overview?.deposit_asset_area)) * 100)?.toFixed(2),
						},
						{
							name: '托管资产',
							num: data?.overview?.deposit_asset_nm || 0,
							square: data?.overview?.deposit_asset_area || 0,
							proportion: ((data?.overview?.deposit_asset_area / (data?.overview?.leased_asset_area + data?.overview?.deposit_asset_area)) * 100)?.toFixed(2),
						},
					];
					this.areaLeases = returnAllPageFunc(5, data?.area_distribute);
					this.settleLegends = data?.industry_distribute?.map(item => item.industry_name);
					this.drawRentalChart();
					this.drawOperateChart();
					this.drawConstructionChart();
					this.drawSettleOutsideChart();
					this.drawSettleCentreChart();
					this.drawSettleWithinChart();
				});
			},
			onLoad(e) {
				const { data } = e;
				const screenWrap = document.getElementById('screenWrap');
				const msg = {
					funcName: 'cameraFly',
					param: {
						id: 22,
					},
				};
				if (data.param && data.param.goStatus) {
					for (let i = 0; i < this.transitionArr.length; i++) {
						this[this.transitionArr[i].name] = false;
					}
					this.initTransitionShow();
					this.$store.commit('changeLoadingStatus', false);
					this.$store.commit('changeMapTypeStatus', false);
					this.$store.commit('changeAnimateDelay', true);
					screenWrap.contentWindow.postMessage(msg, '*');
				}
			},
		},
		mounted() {
			this.initTime();
			this.getGroupData();
			this.initTransitionShow();
			window.addEventListener('message', this.onLoad);
		},
		beforeDestory() {
			window.removeEventListener('message', this.onLoad);
			clearTimeout(this.setTimeValue);
			this.setTimeValue = null;
		},
	};
</script>

<style lang="less">
	@keyframes scaleOut {
		0% {
			opacity: 1;
			transform: scale(0);
		}

		100% {
			opacity: 0;
			transform: scale(1);
		}
	}

	@keyframes doubleFlash {
		0% {
			opacity: 1;
		}

		25% {
			opacity: 0;
		}

		50% {
			opacity: 1;
		}

		75% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	.group {
		z-index: 10;
		position: relative;
		width: 1920px;
		height: 1080px;
		background: #000000;

		.group-main {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: 100%;
			background-image: url(../assets/images/group/bg.jpg);
			background-repeat: no-repeat;

			.group-header {
				z-index: 10;
				display: flex;
				align-items: center;
				position: absolute;
				width: 1917px;
				height: 92px;
				background-size: 100%;
				background-repeat: no-repeat;

				.group-header-wrap {
					display: flex;
					align-items: center;
					position: absolute;
					color: #ffffff;
					right: 60px;
					font-size: 20px;

					p {
						font-size: 20px;
						margin-right: 30px;
					}

					.group-header-menu {
						display: flex;
						align-items: center;

						.group-header-menu-item {
							cursor: pointer;
							display: flex;
							align-items: center;
							margin-right: 20px;

							&:active {
								opacity: 0.5;
							}

							&:last-child {
								margin-right: 0;
							}

							img {
								margin-right: 10px;
							}
						}
					}
				}
			}

			.group-map {
				position: absolute;
				top: 205px;
				left: 489px;
				width: 951px;
				height: 842px;
				background-size: 100%;
				background-image: url(../assets/images/group/map.png);
				background-repeat: no-repeat;

				.group-map-region-wrap {
					position: absolute;

					.group-map-region {
						position: absolute;
						background-size: 100%;
						background-repeat: no-repeat;
					}

					.loop(@i) when (@i>1) {
						.loop((@i - 1));

						.group-map-region-0@{i} {
							animation: scaleOut 3s (@i * 0.5s) both infinite;
						}
					}

					.loop(4);
				}

				.group-map-region-wrap-01 {
					width: 662px;
					height: 329px;
					top: 68px;
					left: 122px;

					.group-map-region-01 {
						z-index: 4;
						width: 143px;
						height: 114px;
						top: 76px;
						left: 258px;
						background-image: url(../assets/images/group/map-item-1.png);
					}

					.group-map-region-02 {
						z-index: 3;
						width: 256px;
						height: 126px;
						top: 101px;
						left: 203px;
						background-image: url(../assets/images/group/map-item-1-1.png);
					}

					.group-map-region-03 {
						z-index: 2;
						width: 473px;
						height: 235px;
						top: 47px;
						left: 95px;
						background-image: url(../assets/images/group/map-item-1-2.png);
					}

					.group-map-region-04 {
						z-index: 1;
						width: 100%;
						height: 100%;
						background-image: url(../assets/images/group/map-item-1-3.png);
					}
				}

				.group-map-region-wrap-02 {
					width: 662px;
					height: 329px;
					top: 356px;
					left: 498px;

					.group-map-region-01 {
						z-index: 4;
						width: 132px;
						height: 113px;
						top: 77px;
						left: 267px;
						background-image: url(../assets/images/group/map-item-2.png);
					}

					.group-map-region-02 {
						z-index: 3;
						width: 256px;
						height: 126px;
						top: 101px;
						left: 203px;
						background-image: url(../assets/images/group/map-item-2-1.png);
					}

					.group-map-region-03 {
						z-index: 2;
						width: 493px;
						height: 245px;
						top: 42px;
						left: 85px;
						background-image: url(../assets/images/group/map-item-2-2.png);
					}

					.group-map-region-04 {
						z-index: 1;
						width: 100%;
						height: 100%;
						background-image: url(../assets/images/group/map-item-2-3.png);
					}
				}

				.group-map-region-wrap-03 {
					width: 703px;
					height: 349px;
					top: 454px;
					left: 461px;

					.group-map-region-01 {
						z-index: 4;
						width: 133px;
						height: 109px;
						top: 91px;
						left: 288px;
						background-image: url(../assets/images/group/map-item-3.png);
					}

					.group-map-region-02 {
						z-index: 3;
						width: 256px;
						height: 126px;
						top: 111px;
						left: 224px;
						background-image: url(../assets/images/group/map-item-3-1.png);
					}

					.group-map-region-03 {
						z-index: 2;
						width: 473px;
						height: 235px;
						top: 57px;
						left: 115px;
						background-image: url(../assets/images/group/map-item-3-2.png);
					}

					.group-map-region-04 {
						z-index: 1;
						width: 100%;
						height: 100%;
						background-image: url(../assets/images/group/map-item-3-3.png);
					}
				}

				.group-map-region-wrap-04 {
					width: 301px;
					height: 380px;
					top: 511px;
					left: 506px;

					.group-map-region-01 {
						z-index: 4;
						width: 199px;
						height: 108px;
						top: 136px;
						left: 62px;
						background-image: url(../assets/images/group/map-item-4.png);
					}

					.group-map-region-02 {
						z-index: 3;
						width: 122px;
						height: 155px;
						top: 112px;
						left: 89px;
						background-image: url(../assets/images/group/map-item-4-1.png);
					}

					.group-map-region-03 {
						z-index: 2;
						width: 203px;
						height: 254px;
						top: 63px;
						left: 49px;
						background-image: url(../assets/images/group/map-item-4-2.png);
					}

					.group-map-region-04 {
						z-index: 1;
						width: 100%;
						height: 100%;
						background-image: url(../assets/images/group/map-item-4-3.png);
					}
				}

				.group-map-dot-wrap {
					position: absolute;
					width: 100%;
					height: 100%;

					.group-map-dot {
						z-index: 10;
						position: absolute;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;

						&:hover {
							p {
								transition: all 0.3s;
								opacity: 1;
							}
						}

						i {
							opacity: 0;
							width: 22px;
							height: 12px;
							background-image: url(../assets/images/group/map-dot.png);
							background-size: 100%;
							background-repeat: no-repeat;
						}

						p {
							opacity: 0;
							color: #ffffff;
							margin-bottom: 4px;
							transition: all 0.3s;
						}
					}

					.loop(@i) when (@i>0) {
						.loop((@i - 1));

						.group-map-dot-@{i} > i {
							animation: doubleFlash 3s (@i * 0.5s) both infinite;
						}
					}

					.loop(12);

					.group-map-dot-1 {
						top: 108px;
						left: 474px;
					}

					.group-map-dot-2 {
						top: 342px;
						left: 259px;
					}

					.group-map-dot-3 {
						top: 446px;
						left: 658px;
					}

					.group-map-dot-4 {
						top: 471px;
						left: 634px;
					}

					.group-map-dot-5 {
						top: 492px;
						left: 661px;
					}

					.group-map-dot-6 {
						top: 486px;
						left: 699px;
					}

					.group-map-dot-7 {
						top: 480px;
						left: 723px;
					}

					.group-map-dot-8 {
						top: 513px;
						left: 698px;
					}

					.group-map-dot-9 {
						top: 535px;
						left: 689px;
					}

					.group-map-dot-10 {
						top: 556px;
						left: 559px;
					}

					.group-map-dot-11 {
						top: 584px;
						left: 555px;
					}

					.group-map-dot-12 {
						top: 615px;
						left: 550px;
					}
				}
			}

			.group-wrap {
				position: absolute;
				z-index: 1;
				width: 100%;
				height: 100%;

				.group-center {
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: absolute;
					top: 106px;
					left: 50%;
					padding: 0 14px;
					transform: translateX(-50%);
					width: 900px;
					height: 88px;
					background: rgba(19, 54, 180, 0.33);

					.group-center-item {
						display: flex;
						justify-content: space-between;

						.group-center-item-icon {
							width: 50px;
							height: 50px;
							margin-right: 8px;
							background-size: 100%;
							background-repeat: no-repeat;
						}

						.group-center-item-data {
							display: flex;
							justify-content: space-between;
							flex-direction: column;

							.group-center-item-name {
								color: #ffffff;
							}

							.group-center-item-num {
								color: #00e8ff;

								span {
									font-size: 30px;
									font-family: 'bigdsfont';
								}
							}
						}
					}

					.group-center-item-01 {
						.group-center-item-icon {
							background-image: url(../assets/images/group/center-icon-01.png);
						}
					}

					.group-center-item-02 {
						.group-center-item-icon {
							background-image: url(../assets/images/group/center-icon-02.png);
						}
					}

					.group-center-item-03 {
						.group-center-item-icon {
							background-image: url(../assets/images/group/center-icon-03.png);
						}
					}

					.group-center-item-04 {
						.group-center-item-icon {
							background-image: url(../assets/images/group/center-icon-04.png);
						}
					}
				}
			}

			.group-item {
				position: absolute;
				background-size: 100%;
				background-repeat: no-repeat;

				.group-item-content {
					position: absolute;
					top: 36px;
				}
			}

			.group-item-left {
				left: 30px;
			}

			.group-item-right {
				right: 30px;
			}

			.group-item-01 {
				width: 422px;
				height: 188px;
				top: 91px;
				background-image: url(../assets/images/group/item-01.png);

				.group-item-content {
					display: flex;
					align-items: center;
					width: 420px;
					height: 150px;

					.group-item-content-left {
						position: relative;
						width: 50%;

						.rentalChart-mask {
							position: absolute;
							top: 50%;
							left: 39px;
							width: 123px;
							height: 123px;
							transform: translateY(-50%);

							.rentalChart-line {
								width: 6px;
								height: 18px;
								position: absolute;
								top: 1px;
								left: 50%;
								margin-left: -1px;
								transform-origin: 0 61px;
								background: #050922;
							}
						}

						.rentalChart-label-wrap {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							position: absolute;
							top: 50%;
							left: 39px;
							width: 123px;
							height: 123px;
							transform: translateY(-50%);

							.rentalChart-label-value {
								color: #ffffff;

								span {
									&:nth-child(1) {
										font-size: 30px;
										font-family: 'bigdsfont';
									}

									&:nth-child(2) {
										opacity: 0.8;
									}
								}
							}

							.rentalChart-label-name {
								font-size: 16px;
								font-weight: 400;
								color: #ffffff;
								opacity: 0.8;
							}
						}
					}

					.group-item-content-right {
						display: flex;
						flex-direction: column;
						justify-content: center;
						width: 50%;
						height: 100%;

						.group-item-content-right-item {
							display: flex;
							height: 43px;
							margin: 10px 0;

							&:nth-child(1) {
								.group-item-content-right-item-icon-rect {
									background: #0173f3;
								}

								.group-item-content-right-item-icon-line {
									background: #0173f3;
								}
							}

							&:nth-child(2) {
								.group-item-content-right-item-icon-rect {
									background: #00e8ff;
								}

								.group-item-content-right-item-icon-line {
									background: #00e8ff;
								}
							}

							.group-item-content-right-item-icon {
								position: relative;
								width: 8px;

								.group-item-content-right-item-icon-rect {
									width: 7px;
									height: 7px;
								}

								.group-item-content-right-item-icon-line {
									position: absolute;
									left: 50%;
									width: 1px;
									height: 43px;
									transform: translateX(-50%);
								}
							}

							.group-item-content-right-item-data {
								margin-left: 12px;

								.group-item-content-right-item-value {
									font-size: 30px;
									font-family: 'bigdsfont';
									font-weight: bold;
									color: #46a4fb;
								}

								.group-item-content-right-item-label {
									font-size: 14px;
									font-weight: 400;
									color: #ffffff;
									opacity: 0.8;
								}
							}
						}
					}
				}
			}

			.group-item-02 {
				width: 422px;
				height: 225px;
				top: 297px;
				background-image: url(../assets/images/group/item-02.png);

				.group-item-content {
					display: flex;
					flex-direction: column;
					width: 420px;
					height: 186px;

					.group-item-content-data {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin: 15px 45px;

						.group-item-content-data-item {
							display: flex;
							justify-content: center;
							flex-direction: column;

							&:nth-child(1) {
								.group-item-content-data-value {
									color: #23cf9b;
								}
							}

							&:nth-child(2) {
								.group-item-content-data-value {
									color: #fcd372;
								}
							}

							&:nth-child(3) {
								.group-item-content-data-value {
									color: #24bfe0;
								}
							}

							.group-item-content-data-value {
								font-size: 30px;
								font-weight: bold;
								text-align: center;
								font-family: 'bigdsfont';
							}

							.group-item-content-data-label {
								font-size: 14px;
								font-weight: 400;
								color: #ffffff;
							}
						}
					}

					.group-item-content-chart {
						display: flex;
						align-items: center;
						justify-content: center;

						.group-item-content-chart-item {
							position: relative;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 50%;
							height: 90px;
							margin-top: 5px;

							.group-item-content-chart-data {
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								position: absolute;
								width: 90px;
								height: 90px;

								.group-item-content-chart-value {
									color: rgba(255, 255, 255, 0.6);

									span {
										color: #ffffff;
										font-size: 30px;
										font-weight: bold;
										font-family: 'bigdsfont';
									}
								}

								.group-item-content-chart-label {
									font-size: 14px;
									color: rgba(255, 255, 255, 0.6);
								}
							}
						}
					}
				}
			}

			.group-item-03 {
				width: 422px;
				height: 140px;
				top: 540px;
				background-image: url(../assets/images/group/item-03.png);

				.group-item-content {
					width: 420px;
					height: 102px;

					.group-item-content-data {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin: 20px 10px;

						.group-item-content-data-item {
							flex: 1;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							height: 62px;
							margin: 0 20px;

							&:nth-child(1) {
								.group-item-content-data-item-name-value {
									color: #3896ff;
								}

								.group-item-content-data-item-value {
									background: #04285f;

									.group-item-content-data-item-value-mix {
										background: linear-gradient(90deg, #0c70df 0%, #3896ff 98%);
									}
								}
							}

							&:nth-child(2) {
								.group-item-content-data-item-name-value {
									color: #3aebb3;
								}

								.group-item-content-data-item-value {
									background: #062d33;

									.group-item-content-data-item-value-mix {
										background: linear-gradient(90deg, #15b35c 1%, #3aeab3 100%);
									}
								}
							}

							&:nth-child(3) {
								.group-item-content-data-item-name-value {
									color: #fff087;
								}

								.group-item-content-data-item-value {
									background: #2a261b;

									.group-item-content-data-item-value-mix {
										background: linear-gradient(90deg, #b37615 0%, #fff188 100%);
									}
								}
							}

							.group-item-content-data-item-name {
								display: flex;
								align-items: center;
								justify-content: space-between;

								.group-item-content-data-item-name-text {
									font-size: 18px;
									font-weight: 500;
									color: #ffffff;
									opacity: 0.8;
								}

								.group-item-content-data-item-name-value {
									font-size: 14px;

									span {
										font-size: 24px;
										font-family: 'bigdsfont';
									}
								}
							}

							.group-item-content-data-item-value {
								width: 100%;
								height: 6px;
								border-radius: 3px;

								.group-item-content-data-item-value-mix {
									height: 6px;
									border-radius: 3px;
									transition: width 1s;
								}
							}

							.group-item-content-data-item-num {
								display: flex;
								align-items: center;
								justify-content: space-between;
								font-size: 14px;
								font-weight: 400;
								color: #ffffff;
								opacity: 0.8;
							}
						}
					}
				}
			}

			.group-item-04 {
				width: 422px;
				height: 354px;
				top: 698px;
				background-image: url(../assets/images/group/item-04.png);

				.group-item-content {
					width: 420px;
					height: 316px;

					.group-item-content-wrap {
						margin: 20px 0;
						height: 296px;
						overflow-y: scroll;

						&::-webkit-scrollbar {
							display: none;
						}

						.el-collapse {
							border: 0;
						}

						.el-collapse-item {
							margin-bottom: 34px;

							&:nth-child(1) {
								.el-collapse-item__header {
									border-left: 2px solid #6effd4;
									background: linear-gradient(90deg, #043536 0%, #051226 100%);

									span {
										&:nth-child(2) {
											color: #29ecb2;
										}
									}

									.el-icon-arrow-right {
										&::before {
											background-image: url(../assets/images/group/item-04-header-01.png);
										}
									}
								}

								.group-item-content-data-item {
									.group-item-content-data-value {
										color: #29ecb2;
									}

									&:nth-child(1) {
										.group-item-content-data-icon {
											background-image: url(../assets/images/group/item-04-item-01-icon-01.png);
										}
									}

									&:nth-child(2) {
										.group-item-content-data-icon {
											background-image: url(../assets/images/group/item-04-item-01-icon-02.png);
										}
									}

									&:nth-child(3) {
										.group-item-content-data-icon {
											background-image: url(../assets/images/group/item-04-item-01-icon-03.png);
										}
									}
								}
							}

							&:nth-child(2) {
								.el-collapse-item__header {
									border-left: 2px solid #09f6ff;
									background: linear-gradient(90deg, #073051 0%, #05112b 100%);

									span {
										&:nth-child(2) {
											color: #09f6ff;
										}
									}

									.el-icon-arrow-right {
										&::before {
											background-image: url(../assets/images/group/item-04-header-02.png);
										}
									}
								}

								.group-item-content-data-item {
									.group-item-content-data-value {
										color: #09f6ff;
									}

									&:nth-child(1) {
										.group-item-content-data-icon {
											background-image: url(../assets/images/group/item-04-item-02-icon-01.png);
										}
									}
								}
							}

							&:nth-child(3) {
								.el-collapse-item__header {
									border-left: 2px solid #bea0ff;
									background: linear-gradient(90deg, #302572 0%, #0e0f32 100%);

									span {
										&:nth-child(2) {
											color: #bea0ff;
										}
									}

									.el-icon-arrow-right {
										&::before {
											background-image: url(../assets/images/group/item-04-header-03.png);
										}
									}
								}

								.group-item-content-data-item {
									.group-item-content-data-value {
										color: #bea0ff;
									}

									&:nth-child(1) {
										.group-item-content-data-icon {
											background-image: url(../assets/images/group/item-04-item-03-icon-01.png);
										}
									}
								}
							}

							&:nth-child(4) {
								.el-collapse-item__header {
									border-left: 2px solid #fded7f;
									background: linear-gradient(90deg, #3e3824 0%, #101222 100%);

									span {
										&:nth-child(2) {
											color: #fded7f;
										}
									}

									.el-icon-arrow-right {
										&::before {
											background-image: url(../assets/images/group/item-04-header-04.png);
										}
									}
								}

								.group-item-content-data-item {
									.group-item-content-data-value {
										color: #fded7f;
									}

									&:nth-child(1) {
										.group-item-content-data-icon {
											background-image: url(../assets/images/group/item-04-item-04-icon-01.png);
										}
									}

									&:nth-child(2) {
										.group-item-content-data-icon {
											background-image: url(../assets/images/group/item-04-item-04-icon-02.png);
										}
									}

									&:nth-child(3) {
										.group-item-content-data-icon {
											background-image: url(../assets/images/group/item-04-item-04-icon-03.png);
										}
									}

									&:nth-child(4) {
										.group-item-content-data-icon {
											background-image: url(../assets/images/group/item-04-item-04-icon-04.png);
										}
									}

									&:nth-child(5) {
										.group-item-content-data-icon {
											background-image: url(../assets/images/group/item-04-item-04-icon-05.png);
										}
									}

									&:nth-child(6) {
										.group-item-content-data-icon {
											background-image: url(../assets/images/group/item-04-item-04-icon-06.png);
										}
									}
								}
							}

							.el-collapse-item__header {
								border: 0;
								height: auto;
								line-height: inherit;
								padding: 4px 0;
								font-size: 18px;
								font-weight: 400;
								color: #ffffff;
								opacity: 0.9;

								.el-icon-arrow-right {
									&::before {
										content: '';
										width: 16px;
										height: 16px;
										display: inline-block;
										background-size: 100%;
										background-repeat: no-repeat;
									}
								}

								span {
									&:nth-child(2) {
										font-size: 12px;

										b {
											font-size: 24px;
											font-family: 'bigdsfont';
										}
									}

									margin-left: 10px;
								}
							}

							.el-collapse-item__wrap {
								border: 0;
								background-color: transparent;

								.el-collapse-item__content {
									padding-bottom: 0;

									.group-item-content-data {
										display: flex;
										flex-wrap: wrap;
										justify-content: space-between;
										align-items: center;
										margin: 0 18px;
										margin-bottom: 0;

										.group-item-content-data-item {
											display: flex;
											align-items: center;
											margin-top: 25px;

											.group-item-content-data-icon {
												width: 44px;
												height: 44px;
												background-size: 100%;
												background-repeat: no-repeat;
											}

											.group-item-content-data-data {
												display: flex;
												flex-direction: column;
												margin-left: 6px;

												.group-item-content-data-value {
													display: flex;
													align-items: center;
													height: 32px;
													font-size: 14px;
													font-weight: 400;

													span {
														font-size: 30px;
														margin-right: 8px;
														font-weight: bold;
														font-family: 'bigdsfont';
													}
												}

												.group-item-content-data-name {
													font-size: 16px;
													font-weight: 400;
													color: #ffffff;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			.group-item-05 {
				width: 422px;
				height: 504px;
				top: 91px;
				background-image: url(../assets/images/group/item-05.png);

				.group-item-content {
					width: 100%;
					height: 466px;
					overflow: hidden;

					.group-item-content-wrap {
						margin: 20px 0;
						height: 425px;

						.group-item-content-title {
							font-size: 16px;
							font-weight: 400;
							color: #ffffff;
							margin-bottom: 20px;

							span {
								&:nth-child(1) {
									margin-left: 35px;
								}

								&:nth-child(2) {
									margin-left: 145px;
								}

								&:nth-child(3) {
									margin-left: 42px;
								}
							}
						}

						.group-item-content-swiper {
							height: 396px;

							.swiper-container {
								height: 100%;

								.swiper-slide {
									.group-item-content-data-wrap {
										height: 358px;

										.group-item-content-data-item {
											display: flex;
											align-items: center;
											margin-bottom: 34px;

											&:nth-child(1) {
												.group-item-content-data-item-num {
													background: #1361d0;
												}

												.group-item-content-data-item-area,
												.group-item-content-data-item-lease {
													color: #35bdff;
												}

												.group-item-content-data-item-progress {
													background: linear-gradient(90deg, #072163 0%, #34b9fb 100%);

													.group-item-content-data-item-progress-circle {
														border-color: #0173f3;

														.group-item-content-data-item-progress-dot {
															background: #99c9ff;
														}
													}
												}
											}

											&:nth-child(2) {
												.group-item-content-data-item-num {
													background: #0661c7;
												}

												.group-item-content-data-item-area,
												.group-item-content-data-item-lease {
													color: #0173f3;
												}

												.group-item-content-data-item-progress {
													background: linear-gradient(90deg, #062163 0%, #0267df 100%);

													.group-item-content-data-item-progress-circle {
														border-color: #0173f3;

														.group-item-content-data-item-progress-dot {
															background: #7fabfc;
														}
													}
												}
											}

											&:nth-child(3) {
												.group-item-content-data-item-num {
													background: #17c08e;
												}

												.group-item-content-data-item-area,
												.group-item-content-data-item-lease {
													color: #21cb99;
												}

												.group-item-content-data-item-progress {
													background: linear-gradient(90deg, #072263 0%, #22ce9b 100%);

													.group-item-content-data-item-progress-circle {
														border-color: #22ce9b;

														.group-item-content-data-item-progress-dot {
															background: #6dffd4;
														}
													}
												}
											}

											&:nth-child(4) {
												.group-item-content-data-item-num {
													background: #0966be;
												}

												.group-item-content-data-item-area,
												.group-item-content-data-item-lease {
													color: #188dfc;
												}

												.group-item-content-data-item-progress {
													background: linear-gradient(90deg, #072163 0%, #188dfc 100%);

													.group-item-content-data-item-progress-circle {
														border-color: #188dfc;

														.group-item-content-data-item-progress-dot {
															background: #72baff;
														}
													}
												}
											}

											&:nth-child(5) {
												.group-item-content-data-item-num {
													background: #d39e1f;
												}

												.group-item-content-data-item-area,
												.group-item-content-data-item-lease {
													color: #fcd270;
												}

												.group-item-content-data-item-progress {
													background: linear-gradient(90deg, #092162 0%, #f4ce72 100%);

													.group-item-content-data-item-progress-circle {
														border-color: #fcd270;

														.group-item-content-data-item-progress-dot {
															background: #ffe4a4;
														}
													}
												}
											}

											&:last-child {
												margin-bottom: 0;
											}

											.group-item-content-data-item-num {
												display: flex;
												align-items: center;
												justify-content: center;
												width: 24px;
												height: 24px;
												color: #ffffff;
												border-radius: 50%;
												font-size: 18px;
												font-weight: bold;
											}

											.group-item-content-data-item-info {
												display: flex;
												flex-direction: column;
												margin-left: 12px;

												.group-item-content-data-item-data {
													display: flex;
													align-items: center;

													.group-item-content-data-item-name {
														width: 190px;
														font-size: 14px;
														font-weight: 500;
														color: #ffffff;
													}

													.group-item-content-data-item-area {
														width: 118px;
														font-size: 22px;
														font-weight: bold;
														font-family: 'bigdsfont';
													}

													.group-item-content-data-item-lease {
														font-size: 22px;
														font-weight: bold;
														font-family: 'bigdsfont';
													}
												}

												.group-item-content-data-item-line {
													margin-top: 14px;

													.group-item-content-data-item-background {
														height: 3px;
														background: #072062;

														.group-item-content-data-item-progress {
															position: relative;
															width: 284px;
															height: 3px;
															transition: width 1s;

															.group-item-content-data-item-progress-circle {
																position: absolute;
																top: 50%;
																right: -7px;
																width: 14px;
																height: 14px;
																opacity: 0.9;
																border-width: 2px;
																border-style: solid;
																border-radius: 50%;
																transform: translateY(-50%);

																.group-item-content-data-item-progress-dot {
																	position: absolute;
																	width: 8px;
																	height: 8px;
																	top: 50%;
																	left: 50%;
																	transform: translate(-50%, -50%);
																	border-radius: 50%;
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}

						.swiper-pagination-bullet {
							width: 24px;
							height: 4px;
							margin: 0 10px;
							border-radius: 0;
							background: #0b409f;
							opacity: 1;
						}

						.swiper-pagination-bullet-active {
							background: #e0ecff;
						}
					}
				}
			}

			.group-item-06 {
				width: 422px;
				height: 429px;
				top: 623px;
				background-image: url(../assets/images/group/item-06.png);

				.group-item-content {
					width: 420px;
					height: 391px;

					.group-item-content-data-wrap {
						flex: 1;
						display: flex;
						flex-direction: column;
						align-items: center;
						margin: 23px;
						margin-bottom: 0;

						.group-item-content-data {
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: space-between;

							.group-item-content-data-item {
								display: flex;
								align-items: center;
								justify-content: center;

								&:nth-child(1) {
									.group-item-content-data-icon {
										background-image: url(../assets/images/group/item-06-icon-01.png);

										span {
											color: #39adf5;
										}
									}

									.group-item-content-data-label > .group-item-content-data-value {
										color: #1c99ed;
									}
								}

								&:nth-child(2) {
									.group-item-content-data-icon {
										background-image: url(../assets/images/group/item-06-icon-02.png);

										span {
											color: #fcdc91;
										}
									}

									.group-item-content-data-label > .group-item-content-data-value {
										color: #fbd16f;
									}
								}

								.group-item-content-data-icon {
									position: relative;
									width: 44px;
									height: 44px;
									background-size: 100%;
									background-repeat: no-repeat;

									span {
										position: absolute;
										right: -2px;
										bottom: 0;
										font-size: 12px;
										font-weight: 400;
									}
								}

								.group-item-content-data-label {
									margin-left: 15px;

									.group-item-content-data-value {
										font-size: 30px;
										font-weight: bold;
										font-family: 'bigdsfont';
									}

									.group-item-content-data-name {
										font-size: 14px;
										font-weight: 400;
										color: #ffffff;
									}
								}
							}
						}

						.group-item-content-data-chart {
							position: relative;
							width: 100%;
							height: 265px;
							margin-top: 10px;

							.chart-item {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);

								&:nth-child(1) {
									border: 2px dotted #0e5153;
									border-radius: 50%;
								}
							}

							.group-item-content-data-chart-mask {
								position: absolute;
								width: 270px;
								height: 270px;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);

								.group-item-content-data-chart-circle {
									position: absolute;
									width: 150px;
									height: 150px;
									top: 50%;
									left: 50%;
									border-radius: 50%;
									transform: translate(-50%, -50%);
									border: 2px solid rgba(225, 225, 225, 0.6);
								}

								.group-item-content-data-chart-dot {
									display: flex;
									align-items: center;
									justify-content: center;
									position: absolute;
									width: 130px;
									height: 130px;
									top: 50%;
									left: 50%;
									border-radius: 50%;
									transform: translate(-50%, -50%);
									border: 4px dotted rgba(225, 225, 225, 0.6);

									span {
										font-size: 18px;
										font-weight: 500;
										color: #ffffff;
									}
								}
							}
						}

						.group-item-content-data-chart-legend {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 100%;
							margin-top: 12px;

							.group-item-content-data-chart-legend-item {
								display: flex;
								align-items: center;
								margin-left: 23px;

								&:nth-child(1) {
									margin-left: 0;
								}

								.group-item-content-data-chart-legend-icon {
									width: 6px;
									height: 6px;
								}

								.group-item-content-data-chart-legend-label {
									font-size: 14px;
									font-weight: 400;
									color: #ffffff;
									margin-left: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
